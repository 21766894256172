import React,
{
  useEffect, useRef, useState, useCallback,
} from 'react';
import Head from 'next/head';
import { connect } from 'react-redux';
import classNames from 'classnames';
import jwt from 'jsonwebtoken';
import getConfig from 'next/config';

import {
  fetchRelatedArticles, loadArticle, loadMostPopularList, getStartTodayArticlePermissions,
} from 'redux/modules/article';
import { bulkUpdate as navUpdateAction } from 'redux/modules/navbar';
import { setPageView, setView, setChromeless } from 'redux/modules/shared';

import BTE from 'lib/BTE';
import {
  MOBILE_ACQUISITION_BANNER,
  NEWSLETTER_SIGNUP_POPUP_MODULE,
  SHOW_USER_ACTIONS,
  ARTICLE_HERO_LAYOUT,
  MOST_POPULAR_MODULE_BRAND_CONFIG,
  RELATED_ARTICLE_BOTTOM_RECIRC,
} from 'lib/brandFeatures';
import { BREAKING_NEWS_FLAGS } from 'lib/breakingNews';
import { ArticleContext, useFeatureFlagContext } from 'lib/ContextTypes';
import { getVideosFromArticlePayload } from 'lib/getVideosFromPayload';
import { getFeatureConfigForBrand } from 'lib/getFeatureStatus';
import { getPathName } from 'lib/getPathName';
import LegacyIDService from 'lib/LegacyIDService';
import { navbar, NAVBAR_THEME } from 'lib/navbar';
import { getShouldShowEventDayRecirc } from 'lib/eventDayRecirc';
import { sectionContainSlug, extractTermSlugsAsCommaSeparatedList, isElectionsTaxonomy } from 'lib/taxonomy';
import { setLinkHeaders } from 'lib/setLinkHeaders';
import {
  isShellArticle,
  shouldShowTableOfContents,
  getTableOfContentsLabel,
  getTableOfContentsEntries,
} from 'lib/article';
import { VIEW } from 'lib/view';

import AdsBundle from 'components/AdsBundle';
import { ThemesCssBundle } from 'components/ThemesCssBundle';
import { AnalyticsLaunchScripts } from 'components/AnalyticsLaunchScripts';
import { getTaboolaRecoReelEnabled } from 'components/Article/getSections/insertTaboolaRecoReel';
import ArticleHero from 'components/Article/ArticleHero';
import ArticleBody from 'components/Article/ArticleBody';
import Breakpoints from 'lib/Breakpoints';
import { ErrorBoundary } from 'components/ErrorBoundary';
import IntObserver from 'components/IntObserver';
import PopupModule from 'components/NewsletterSignup/PopupModule';
import MobileBanner from 'components/MobileBanner';
import { ArticleMetadata, GlobalMetadata } from 'components/PageMetadata';
import { HeaderAndFooter } from 'components/services/HeaderAndFooter';
import { EventDayRecirc } from 'components/EventDayRecirc';
import { TableOfContents } from 'components/TableOfContents';
import ScrollingAnalytics from 'components/ScrollingAnalytics';
import { TopicSubNav } from 'components/TopicSubNav';
import IconfontStyleTag from 'components/IconfontStyleTag';
import UniversalCheckout from 'components/UniversalCheckout';
import { ArticleDuet } from 'components/ArticleDuet';
import { CommerceAmazonContentInsights } from 'components/Commerce/AmazonContentInsights';
import { UserInteractionTracker } from 'components/UserInteractionTracker';
import { VideoObjectLDScripts } from 'components/VideoObjectLDScripts';
import { isStartTodayUrl } from 'lib/startTodayUtils';
import { temporaryLogCacheTag } from 'lib/articleUtils';

import ErrorPage from '../_error';

import 'assets/styles/main.scss';
import 'assets/styles/toolkit.scss';
import styles from './styles.module.scss';
import globalContainerStyles from '../globalContainerStyles.module.scss';

const block = 'article';
const pageView = 'article';
const startTodayAppPageView = 'start-today-article';
const externalLink = 'externalLink';

/**
 * Takes some args and returns whether or not to show the timestamp in the byline
 * area of the ArticleBody. Exported for testing purposes
 * @param {DateTime} date Object containing the content's date metadata
 * @param {boolean} breakingNews If the content is marked as breaking news
 * @param {string} flag Text of the flag entered by editors (e.g. 'BREAKING NEWS')
 * @returns {boolean}
 */
export const getShowBylineTimestamp = (date, breakingNews, flag) => {
  // Timestamps can also appear in the ArticleHero's LiveFlag for "Live Blogs" or
  // "Breaking News Articles" with specific flag labels
  const isBreakingNewsFlagged = breakingNews
    && (
      flag === BREAKING_NEWS_FLAGS['BREAKING NEWS']
      || flag === BREAKING_NEWS_FLAGS.DEVELOPING
    );

  const articleDatePubished = date?.publishedAt;
  const articleDateCreated = date?.createdAt;
  const isUpdated = articleDateCreated !== articleDatePubished;

  // In an initially posted LiveBlogs or when specific flag labels are active the Timestamp is
  // only rendered in the ArticleHero instead of ArticleBody
  const isUpdatedBreakingNewsFlagged = isBreakingNewsFlagged && isUpdated;

  return isUpdatedBreakingNewsFlagged || !isBreakingNewsFlagged;
};

export const navbarConfig = {
  /**
   * Determines the navbar theme based on content and vertical
   * @param {object} props Component props
   * @param {object} props.content Article content object
   * @param {string} props.vertical Vertical name
   * @returns {string} The navbar theme to use
   */
  theme({ content, vertical }) {
    if (Object.keys(content).length) {
      const {
        source,
        taxonomy: { sections },
      } = content;

      if ((content?.presentation?.style ?? false) === 'DUET') {
        if (vertical === 'today') return NAVBAR_THEME.LIGHT;
        return NAVBAR_THEME.DARK;
      }

      const isShoppingSection = sectionContainSlug(sections, 'shopping');
      if (isShoppingSection) {
        return NAVBAR_THEME.LIGHT;
      }

      const getShoppingSourceName = source?.organization?.name ?? false;
      if (vertical === 'news' && getShoppingSourceName === 'StackCommerce') {
        return NAVBAR_THEME.VERTICAL;
      }
    }

    if (['today'].indexOf(vertical) >= 0) {
      return NAVBAR_THEME.LIGHT;
    }
    return NAVBAR_THEME.VERTICAL;
  },
  /**
   * Retrieves the headline from the content object
   * @param {object} props Component props
   * @param {object} props.content Article content object
   * @returns {string|null} The headline or null if not available
   */
  headline: ({ content }) => content?.headline ?? null,
  /**
   * Determines the active state based on vertical and navbar content
   * @param {object} props Component props
   * @param {string} props.vertical Vertical name
   * @param {object} props.navbarContent Navbar content object
   * @returns {number|null} The active state value
   */
  activeAt: ({ vertical, navbarContent }) => {
    const hasElectionsNav = isElectionsTaxonomy(navbarContent?.taxonomy?.primaryTopic?.slug);
    return (vertical === 'today' && !isShellArticle(navbarContent))
    || (navbarContent?.subnav && !hasElectionsNav)
      ? null
      : 160;
  },
  /**
   * Determines whether to show shortcuts based on the content's primary topic
   * @param {object} props Component props
   * @param {object} props.content Article content object
   * @returns {boolean} Whether to show shortcuts
   */
  showShortcuts: ({ content }) => {
    const primaryTopic = content?.taxonomy?.primaryTopic;
    const electionYears = ['2020', '2022'];
    if (primaryTopic) {
      return !electionYears.some((year) => (
        [
          `${year}-election`,
          `elecciones-estados-unidos-${year}`,
        ].includes(extractTermSlugsAsCommaSeparatedList(primaryTopic))
      ));
    }
    return true;
  },
};

/**
 * Maps the state to props for the ArticlePage component.
 * @param {object} state The current state of the Redux store.
 * @param {object} state.article The article state.
 * @param {object} state.shared The shared state.
 * @param {object} state.router The router state.
 * @param {object} state.navbar The navbar state.
 * @returns {object} The mapped props.
 */
const mapStateToProps = ({
  article, shared, router, navbar: navStore,
}) => ({
  articles: article.content,
  vertical: shared.vertical,
  isChromeless: shared.isChromeless,
  view: shared.view,
  path: router.path,
  active: navStore.active,
  mostPopularStoryListObject: article.mostPopularStoryList,
  relatedArticles: article.relatedArticles,
});

/**
 * Maps the dispatch to props for the ArticlePage component.
 * @param {Function} dispatch The dispatch function.
 * @returns {object} The mapped actions.
 */
const mapActionsToProps = (dispatch) => ({
  /**
   * Dispatches the navUpdateAction with the given theme.
   * @param {string} theme - The theme to update the navbar with.
   */
  navUpdate: (theme) => dispatch(navUpdateAction(theme)),
});

/**
 * ArticlePage component
 * @param {object} props - The component props.
 * @param {bool} [props.active]
 * @param {Array.<object>} props.articles - PropTypes.arrayOf(ArticlePropType)
 * @param {Array.<object>} [props.eventDayList] - PropTypes.arrayOf(PropTypes.objectOf(PropTypes.string))
 * @param {bool} [props.isChromeless]
 * @param {bool} [props.isStartTodayAppRoute]
 * @param {object} props.layout - servicesLayoutPropType.isRequired
 * @param {object} [props.mostPopularStoryListObject]
 * @param {Function} props.navUpdate
 * @param {string} [props.path]
 * @param {object} [props.relatedArticles] - PropTypes.arrayOf(ArticlePropType),
 * @param {bool} [props.shouldShowEventDayRecirc]
 * @param {number} props.statusCode
 * @param {string} props.vertical
 * @param {string} [props.view]
 * @returns {React.ReactElement} The rendered component.
 */
const ArticlePage = (props) => {
  const {
    active,
    articles,
    eventDayList,
    shouldShowEventDayRecirc,
    isChromeless,
    isStartTodayAppRoute,
    layout,
    mostPopularStoryListObject,
    navUpdate,
    path,
    relatedArticles,
    statusCode,
    vertical,
    view,
  } = props;

  const [isMobileOrTabletBreakpoint, setIsMobileOrTabletBreakpoint] = useState(Breakpoints.isSorM());
  const [isScrolledPastArticleHeadline, setIsScrolledPastArticleHeadline] = useState();

  const {
    'taboola-reco-reel': taboolaRecoReelEnabledLD,
    'shoppable-articles': shoppableArticlesEnabled,
    'topic-subnav': isTopicSubnavEnabled,
    'show-table-of-contents': tableOfContentsFeatureFlag,
    'primary-media-banner': primaryMediaBannerEnabled,
  } = useFeatureFlagContext();

  /**
   * Monitors the breakpoint and updates the state accordingly.
   */
  const monitorBreakpoint = useCallback(() => {
    setIsMobileOrTabletBreakpoint(Breakpoints.isSorM());
  }, []);

  /**
   * Monitors the position of the article headline and updates the state accordingly.
   */
  const monitorArticleHeadlinePosition = useCallback(() => {
    const articleTitleBottom = document.querySelector('.article-hero-headline').getBoundingClientRect().bottom;
    setIsScrolledPastArticleHeadline(window.scrollY > articleTitleBottom + 60);
  }, []);
  const prevActive = useRef();
  // UpdateTheme if active on StackCommerce articles
  useEffect(() => {
    if ((articles?.[0]?.source?.organization?.name ?? false) === 'StackCommerce') {
      if (!prevActive.current.active && active) {
        navUpdate({ theme: NAVBAR_THEME.VERTICAL });
      } else if (prevActive.current.active && !active) {
        navUpdate({ theme: NAVBAR_THEME.LIGHT });
      }
    }
  }, [articles, active, navUpdate]);

  useEffect(() => {
    /**
     * Sets up event listeners for resize and scroll events.
     */
    BTE.on('resize', monitorBreakpoint);
    BTE.on('scroll', monitorArticleHeadlinePosition);

    /**
     * Removes event listeners for resize and scroll events.
     */
    return () => {
      BTE.remove('resize', monitorBreakpoint);
      BTE.remove('scroll', monitorArticleHeadlinePosition);
    };
  }, []);

  /**
   * Handles errors in the main content.
   * @returns {React.ReactElement} The error page component.
   */
  const handleMainContentError = () => <ErrorPage statusCode={500} />;

  /**
   * Handles the view event when the article is in view.
   * @param {IntersectionObserverEntry[]} e - The intersection observer entries.
   */
  const onView = useCallback((e) => {
    if (e?.[0]?.isIntersecting) {
      const id = articles?.[0]?.id;
      BTE.trigger('articleInView', id);
    }
  }, [articles]);

  const mostPopularStoryListItems = mostPopularStoryListObject?.content?.items;

  if (parseInt(statusCode, 10) >= 400) {
    return (
      <ErrorPage statusCode={statusCode} layout={layout} />
    );
  }

  const article = articles?.length && articles[0];

  const {
    id: articleID,
    body: articleBody,
    breakingNews,
    date,
    ecommerceEnabled,
    flag,
    taxonomy: {
      allTerms = [],
    },
  } = article;

  const { id } = article;

  const isShopThisList = allTerms.some(({ path: p }) => p === 'today/label/shop-this-list');

  const isShoppable = shoppableArticlesEnabled && ecommerceEnabled && isShopThisList;

  const hasElectionsNav = isElectionsTaxonomy(article?.taxonomy?.primaryTopic?.slug);

  const tableOfContentsEnabled = shouldShowTableOfContents(
    tableOfContentsFeatureFlag,
    vertical,
    allTerms,
  );

  const tableOfContentsEnabledAndMobile = tableOfContentsEnabled && isMobileOrTabletBreakpoint;

  const shouldRenderMobileTableOfContents = tableOfContentsEnabledAndMobile
    && isScrolledPastArticleHeadline;

  const tableOfContentsLabel = getTableOfContentsLabel(allTerms);

  const tableOfContentsLinks = getTableOfContentsEntries(articleBody, vertical);

  const shouldShowSubNav = Boolean(
    article?.subnav
    && !hasElectionsNav
    && isTopicSubnavEnabled
    && !shouldShowEventDayRecirc,
  );


  const displayMobileBanner = getFeatureConfigForBrand(
    MOBILE_ACQUISITION_BANNER,
    vertical,
  );
  const shouldNewsletterSignupPopupModuleRender = getFeatureConfigForBrand(
    NEWSLETTER_SIGNUP_POPUP_MODULE,
    vertical,
  );

  const heroLayoutType = getFeatureConfigForBrand(
    ARTICLE_HERO_LAYOUT,
    vertical,
  );

  // 100000% top margin to pretend the viewport is "infinitely" tall, so you will
  // still be considered "past" the top of the article
  // -100% bottom margin to get the 0 threshold to trigger only once the top of the article
  // passes the top of the viewport
  const intObsRootMargin = '100000% 0px -100% 0px';

  const getRightRailAdConfig = vertical !== 'select' ? null : () => ({ display: false });

  const shouldRenderRightRailTaboola = vertical !== 'select' ? null : () => false;

  const showBylineTimestamp = getShowBylineTimestamp(date, breakingNews, flag);

  const userActionConfig = getFeatureConfigForBrand(SHOW_USER_ACTIONS, vertical);
  let showUserActions = !!userActionConfig;
  if (userActionConfig?.hideFromChromeless) {
    showUserActions = !isChromeless;
  }
  // hide social icons in Start Today App view
  if (isStartTodayAppRoute) {
    showUserActions = false;
  }

  const taboolaRecoReelEnabled = taboolaRecoReelEnabledLD && getTaboolaRecoReelEnabled(vertical);

  const allVideos = getVideosFromArticlePayload(article);

  const content = (
    <ErrorBoundary errorHandler={handleMainContentError}>
      <IntObserver
        rootMargin={intObsRootMargin}
        threshold={0}
        callback={onView}
      >
        <ScrollingAnalytics contentType="article" contentUrl={article.url.primary}>
          <ArticleContext.Provider value={article}>
            <GlobalMetadata hidden={isStartTodayAppRoute} />
            <ArticleMetadata article={article} vertical={vertical} />
            <VideoObjectLDScripts videos={allVideos} vertical={vertical} />
            {shouldShowSubNav && (
              <TopicSubNav
                titleText={article.subnav.headline?.primary}
                titleLink={article.subnav.externalUrl}
                linkItems={article.subnav.content?.items}
                isActiveLink={(item) => item.id === id}
                logo={article.subnav.teaseImage}
              />
            )}
            {tableOfContentsEnabledAndMobile && (
              <UserInteractionTracker events={['click', 'keydown']}>
                {(lastInteraction) => (
                  <TableOfContents
                    topOffset={shouldShowEventDayRecirc ? '105px' : '60px'}
                    title={tableOfContentsLabel}
                    links={tableOfContentsLinks}
                    visible={Boolean(shouldRenderMobileTableOfContents)}
                    lastInteraction={lastInteraction}
                    testId="article-table-of-content"
                  />
                )}
              </UserInteractionTracker>
            )}
            <article
              key={`articleWrapper-${article.id}`}
              className={classNames(styles.article, block)}
            >
              {shouldNewsletterSignupPopupModuleRender && <PopupModule />}
              {displayMobileBanner && <MobileBanner />}
              {article.presentation?.style === 'DUET'
                ? (
                  <ArticleDuet
                    article={article}
                    path={path}
                    vertical={vertical}
                    isChromeless={isChromeless}
                    showCreatedDate
                    getRightRailAdConfig={getRightRailAdConfig}
                    showUserActions={showUserActions}
                    shouldRenderRightRailTaboola={shouldRenderRightRailTaboola}
                  />
                )
                : (
                  <>
                    <ArticleHero
                      content={article}
                      isPresentationStyleWide={article.presentation?.style === 'WIDE'}
                      isChromeless={isChromeless}
                      heroLayoutType={heroLayoutType}
                      primaryMediaBannerEnabled={primaryMediaBannerEnabled}
                      vertical={vertical}
                      isShoppable={isShoppable}
                    />
                    <ArticleBody
                      vertical={vertical}
                      article={article}
                      index={0}
                      path={path}
                      isChromeless={isChromeless}
                      view={view}
                      isShoppable={isShoppable}
                      getRightRailAdConfig={getRightRailAdConfig}
                      shouldRenderRightRailTaboola={shouldRenderRightRailTaboola}
                      taboolaRecoReelEnabled={taboolaRecoReelEnabled}
                      showUserActions={showUserActions}
                      showBylineTimestamp={showBylineTimestamp}
                      mostPopularStoryListItems={mostPopularStoryListItems}
                      relatedArticles={relatedArticles}
                      tableOfContentsEnabled={tableOfContentsEnabled}
                    />
                  </>
                )}
            </article>
          </ArticleContext.Provider>
        </ScrollingAnalytics>
      </IntObserver>
    </ErrorBoundary>
  );

  const wrappedContent = isChromeless ? (
    content
  ) : (
    <HeaderAndFooter
      layout={layout}
      contentAboveHeader={(
        shouldShowEventDayRecirc
        && (
          <EventDayRecirc
            headline={eventDayList.headline}
            externalUrl={eventDayList.externalUrl}
            content={eventDayList.content}
          />
        )
      )}
      isNavbarSticky={!shouldShowSubNav}
    >
      {content}
    </HeaderAndFooter>
  );

  return (
    <>
      <Head>
        <IconfontStyleTag />
      </Head>
      <ThemesCssBundle vertical={vertical} />

      <div
        className={classNames(
          globalContainerStyles.container,
          'bg-knockout-primary',
        )}
        id="content"
      >
        {wrappedContent}
      </div>
      <AnalyticsLaunchScripts isChromeless={isChromeless} pageID={articleID} />
      <AdsBundle isArticle vertical={vertical} />
      <UniversalCheckout vertical={vertical} />
      <CommerceAmazonContentInsights />
    </>
  );
};

/**
 * Fetches initial props for the ArticlePage component.
 * @param {object} ctx - The context object.
 * @returns {Promise<object>} The initial props.
 */
ArticlePage.getInitialProps = async (ctx) => {
  const {
    req: {
      originalUrl: url,
      headers: {
        authorization,
      } = {},
      params: {
        id: rawID,
      },
    } = {},
    res: {
      locals: {
        getLaunchDarklyFlag,
        launchDarklyFlags,
        vertical,
        data: {
          curatedList: eventDayList,
        } = {},
        logger,
      },
    } = {},
    store,
  } = ctx;

  let promises;
  const id = LegacyIDService.article(rawID);
  const isStartTodayAppRoute = isStartTodayUrl(url);
  const token = authorization?.split(' ')[1] ?? '';

  // If the article is a Start Today article,
  // we need to make an additional request to get the article content
  if (isStartTodayAppRoute) {
    const {
      serverRuntimeConfig: { JWT_PUBLIC_KEY },
    } = getConfig();

    const forbiddenResponse = {
      navigating: false,
      pageView,
      isStartTodayAppRoute,
      statusCode: 403,
      eventDayList,
    };

    // If JWT_PUBLIC_KEY is not set, return a 403
    if (!JWT_PUBLIC_KEY) {
      return forbiddenResponse;
    }

    const additionalRequestData = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    // Verify if token expired and vertical is today.
    // This is a quicker way to return a 403 than calling bento api.
    try {
      const decodedToken = jwt.verify(token, Buffer.from(JWT_PUBLIC_KEY, 'base64').toString('utf-8'));
      const { exp, brand } = decodedToken;

      const isToday = brand === 'today';
      const isValidToken = exp >= Math.floor(Date.now() / 1000);

      // If the token is not for the Today brand
      // or is expired, return a 403
      if (!isToday || !isValidToken) {
        return forbiddenResponse;
      }
    } catch (error) {
      // If the token is invalid, return also a 403
      return forbiddenResponse;
    }

    // Call the bento api to check if token has access to the article
    const { payload: { data, status } = {} } = await store.dispatch(
      getStartTodayArticlePermissions(id, { additionalRequestData }),
    ) ?? {};

    // If the token does not have access to the article, return a 403
    if (!data) {
      return { ...forbiddenResponse, statusCode: status };
    }

    promises = [
      store.dispatch(setPageView(startTodayAppPageView)),
      store.dispatch(setView(VIEW.START_TODAY_APP)),
      store.dispatch(setChromeless(true)),
      store.dispatch(loadArticle(id, { additionalRequestData })),
    ];


    // Otherwise, we can just load the article content and set the page view
  } else {
    promises = [
      store.dispatch(loadArticle(id)),
      store.dispatch(setPageView(pageView)),
    ];
  }

  const shouldRenderMostPopularStoryList = getFeatureConfigForBrand(
    MOST_POPULAR_MODULE_BRAND_CONFIG,
    vertical,
  );

  let isMostPopularModuleEnabled = false;
  try {
    isMostPopularModuleEnabled = await getLaunchDarklyFlag('is-most-popular-module-enabled');
  } catch (e) {
    console.error('Could not retrieve feature flag for Most Popular Module. Error is logged directly below.');
    console.error(e);
  }

  const mostPopularQueryIdsByVertical = { news: 'nccl00011223344', msnbc: 'nccl00088112233', today: 'tdcl00055667799' };

  if (isMostPopularModuleEnabled && shouldRenderMostPopularStoryList) {
    const curatedListId = mostPopularQueryIdsByVertical[vertical];
    promises.push(store.dispatch(loadMostPopularList(curatedListId)));
  }

  await Promise.all(promises);

  const { article } = store.getState();
  const articleContent = article?.content?.[0];
  const articleSubType = articleContent?.subType;
  const primaryURL = articleContent?.url?.primary ?? '';
  const headline = articleContent?.headline ?? {};
  const articleId = articleContent?.id ?? '';
  const primaryLabelTaxonomyPath = articleContent?.taxonomy?.primaryLabel?.path;
  const unibrowTaxonomyPath = articleContent?.unibrow?.taxonomyPath;

  const shouldShowEventDayRecirc = getShouldShowEventDayRecirc(
    launchDarklyFlags,
    vertical,
    articleContent?.taxonomy?.allTerms?.map((t) => t?.path),
    eventDayList,
  );

  if (articleSubType === externalLink) {
    ctx.res.redirect(primaryURL);
  }

  const statusCode = article?.error?.status ?? 200;

  await store.dispatch(navUpdateAction({
    shareUrl: getPathName(primaryURL),
    headline,
  }));

  setLinkHeaders(ctx.res, vertical);

  const taxonomyPath = primaryLabelTaxonomyPath ?? unibrowTaxonomyPath;

  const relatedArticleBottomRecirc = getFeatureConfigForBrand(
    RELATED_ARTICLE_BOTTOM_RECIRC,
    vertical,
  );

  const shouldShowRecirc = relatedArticleBottomRecirc && !articleContent?.ecommerceEnabled;

  if (shouldShowRecirc) {
    const params = {
      taxonomyPath,
      currentArticle: articleId,
      queryLimit: 10,
    };
    await store.dispatch(fetchRelatedArticles(params));
  }

  if (vertical === 'today' && url.startsWith('/shop')) {
    temporaryLogCacheTag(logger, articleContent);
  }

  return {
    eventDayList,
    isStartTodayAppRoute,
    navigating: false,
    pageView,
    shouldShowEventDayRecirc,
    statusCode,
  };
};

export default navbar(navbarConfig)(
  connect(mapStateToProps, mapActionsToProps)(ArticlePage),
);

// exported for testing
export { ArticlePage as UnwrappedArticlePage };
